import './App.css';
import {useEffect, useState} from "react"
import axios from "axios"
import Switch from './utils/switch';
function App() {

 const [allStudents,setAllStudents] = useState([])


  const getAllStudents = async () =>{
      const {data} =await axios.get("https://rest.techiepanda.in/getAllStudents") 


      setAllStudents(data)
    }

    const [allCourses,setallCourses] = useState()

    const getCourse =async ()=>{
      const {data} = await axios.get("https://rest.techiepanda.in/getallCourses")
      setallCourses(data)
    }

    useEffect(()=>{
      getAllStudents()
      getCourse()
      
    },[allStudents?.length])

    const [filterList , setFilterList] = useState("")

    const searchStudent = (filterValue) => {
      if(filterValue?.length > 0 ){

        const filterStu = allStudents?.filter((e) => 
        e.email.includes(filterValue) || 
        e.firstName.includes(filterValue) || 
        e.lastName.includes(filterValue)
      );

        setFilterList(filterStu)
      }
      else{
        setFilterList("")

      }

    }

    const currentData = filterList ? filterList: allStudents

  return (
    <div className="BlockedContainer">

        <input onChange={(e)=>searchStudent(e.target.value)} placeholder='Search student by name or email' style={{width:"90%",height:"2rem",margin:"1rem 2rem"}}/>

      <div>
      <table className="table">
        <thead>
          <tr>
            <th>S.no</th>
            <th>Student Name</th>
            <th>Course Enrolled</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Blocked</th>
            <th>Enrolled Date</th>
          </tr>
        </thead>
        <tbody>
          {currentData?.map((row, index) => {

            return(
                  <tr key={index}>
                    <td>{ index + 1}</td>
                    <td>{row?.firstName}</td>
                    <td>{ allCourses.find((course)=>course?._id == row?.course && row.course[0])?.course_name || "Course Not Enrolled" }</td>
                    <td>{row.email}</td>
                    <td>{row.phone}</td>
                    <td><Switch defVal={row.blocked ? row.blocked : false} stId = {row?._id}/></td>
                    <td>{new Date(row?.createdAt).toLocaleString()}</td>
                  </tr>
                )})}
         
        </tbody>
      </table>
  
    </div>
    </div>  
  );
}

export default App;
