import React, { useEffect, useState } from 'react';
import './Switch.css'; // Assuming you have a CSS file for styling
import axios from 'axios';

const Switch = ({defVal,stId}) => {
  const [checked, setChecked] = useState(defVal);

  const handleChange = () => {
    setChecked(!checked);
   
  };
  
  useEffect(()=>{
    axios.post("https://rest.techiepanda.in/updateStudent",{userId:stId,blocked:checked}).then((resp)=>{
      console.log(resp)
    }).catch((err)=> console.log(err))
  },[checked])
  

  return (
    <label className="switch">
      <input type="checkbox" checked={checked} onChange={handleChange} />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;
